import React, { useRef } from 'react';
import { useEffect, useCallback } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, Button, ListItem, Box, IconButton } from '@mui/material';
import WebAppzAPI from './../../API/WebAppzAPI';
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import { useTranslation } from 'react-i18next';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const parseDateString = (dateString) => {
    const dateParts = dateString.split(/[- :]/);
    return new Date(dateParts[0], dateParts[1] - 1, dateParts[2], dateParts[3], dateParts[4], dateParts[5]);
};

// Функция для расчета разницы в днях
const calculateDaysDifference = (endPayDate) => {
    const currentDate = new Date();
    const endDate = parseDateString(endPayDate);

    const timeDifference = endDate - currentDate;
    const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

    return daysDifference > 0 ? daysDifference : -Math.abs(daysDifference);
};

const ShopCard = ({ handleOpenShops, userData, openShop, setOpenShop, shop, openModal, setOpenModal, isOpen = false }) => {
    const { t } = useTranslation();
    const [open, setOpen] = React.useState(openShop);
    const backButtonRef = useRef(null);
    const [ok, setOk] = React.useState(window.Telegram.WebApp.initDataUnsafe.start_param !== undefined ? false : true);
    const [stats, setStats] = React.useState({
        total_users: 0,
        users_today: 0,
        total_orders: 0,
        total_orders_month: 0,
        total_orders_today: 0,
        total_cost: 0,
        total_month_cost: 0,
        total_day_cost: 0,
        total_items: 0,
        items_day: 0,
        days_since_creation: ''
    });

    const handleOpen = () => {
        setOpen(true);
        setOpenShop(true);
    };

    const handleClose = () => {
        setOpen(false);
        setOpenShop(false);
    };
    
    const handleBackButtonClick = useCallback((isOpen_) => {
        if (openShop || isOpen_) {
            setOpenShop(false);
            setOpen(false);
        } else if (openModal) {
            setOpenModal(false);
        }
        if(shop === false) {
            setOk(false);
        }
    }, [openShop, openModal]);

    useEffect(() => {
        const backButton = window.Telegram.WebApp.BackButton;
        backButtonRef.current = backButton;

        if ((openShop || openModal) && !isOpen) {
            window.Telegram.WebApp.MainButton.hide();
            window.Telegram.WebApp.SecondaryButton.hide();
            backButtonRef.current.show();
            backButtonRef.current.onClick(handleBackButtonClick);
        } else if((openShop || openModal) && isOpen) {
            window.Telegram.WebApp.MainButton.hide();
            window.Telegram.WebApp.SecondaryButton.hide();
            backButtonRef.current.show();
            backButtonRef.current.onClick(() => handleBackButtonClick(true));
        } else {
            window.Telegram.WebApp.MainButton.show();
            window.Telegram.WebApp.SecondaryButton.show();
            backButtonRef.current.hide();
            backButtonRef.current.offClick(handleBackButtonClick);
        }

        return () => {
            if(!ok && !openShop && !openModal && !isOpen) {
                window.Telegram.WebApp.SecondaryButton.show();
                window.Telegram.WebApp.MainButton.show();
            }
            backButtonRef.current.hide();
            backButtonRef.current.offClick(handleBackButtonClick);
        };

    }, [handleBackButtonClick, openShop, openModal, isOpen, shop, ok]);

    React.useEffect(() => {
        const getStat = async () => {
            const statS = await WebAppzAPI.getStat({ userData: userData, shopid: shop.shopID });
            setStats(statS);
        };

        if (open) {
            getStat();
        } else {
            setStats({
                total_users: 0,
                users_today: 0,
                total_orders: 0,
                total_orders_month: 0,
                total_orders_today: 0,
                total_cost: 0,
                total_month_cost: 0,
                total_day_cost: 0,
                total_items: 0,
                items_day: 0,
                days_since_creation: ''
            });
        }
    }, [open, userData, shop]);

    if (shop === false) {
        return <></>;
    }

    const openInvoice = async (payment) => {

        if (payment === 'CARD') {

            const createPaymentRequest = await WebAppzAPI.createPay({
                'userid': userData.userid,
                'shopidpayment': shop.shopID,
                'type_transaction': "payment_shop",
                'currency': "RUB"
            }, userData);

            if(createPaymentRequest.data.status !== undefined && createPaymentRequest.data.status === true) {
                const responsePayTG = await window.Telegram.WebApp.openInvoice(createPaymentRequest.data.invoice_link);
                if (responsePayTG === 'paid') {
                    window.Telegram.WebApp.showAlert(t('payment_successful'));
                } else if (responsePayTG === 'failed') {
                    window.Telegram.WebApp.HapticFeedback.notificationOccurred('error');
                } else {
                    window.Telegram.WebApp.HapticFeedback.notificationOccurred('warning');
                }
                // window.location.href = 'https://t.me/webappzconnectbot';
                // window.Telegram.WebApp.close();
            } else if(createPaymentRequest.data.status !== undefined && createPaymentRequest.data.status === false) {
                window.Telegram.WebApp.showAlert(createPaymentRequest.data.message);
            } else {
                window.Telegram.WebApp.showAlert('Server error');
            }

        } else if (payment === 'STARS') {

            const createPaymentRequest = await WebAppzAPI.createPay({
                'userid': userData.userid,
                'shopidpayment': shop.shopID,
                'type_transaction': "payment_shop",
                'currency': "XTR"
            }, userData);

            if (createPaymentRequest.data.invoice_link !== undefined) {
                const responsePayTG = await window.Telegram.WebApp.openInvoice(createPaymentRequest.data.invoice_link);
                if (responsePayTG === 'paid') {
                    window.Telegram.WebApp.showAlert(t('payment_successful'));
                } else if (responsePayTG === 'failed') {
                    window.Telegram.WebApp.HapticFeedback.notificationOccurred('error');
                } else {
                    window.Telegram.WebApp.HapticFeedback.notificationOccurred('warning');
                }
                console.log(responsePayTG);
            } else {
                window.Telegram.WebApp.showAlert(t("start_payment_failed"));
            }
        } else if(payment === 'BALANCE_CONNECT') {

            const createPaymentRequest = await WebAppzAPI.createPay({
                'userid': userData.userid,
                'shopidpayment': shop.shopID,
                'type_transaction': "payment_shop",
                'currency': "BALANCE_CONNECT"
            }, userData);

            console.log(createPaymentRequest.data);

            if (createPaymentRequest.data.invoice_link !== undefined) {
                const responsePayTG = await window.Telegram.WebApp.openTelegramLink(createPaymentRequest.data.invoice_link);
                if (responsePayTG === 'paid') {
                    window.Telegram.WebApp.showAlert(t('payment_successful'));
                } else if (responsePayTG === 'failed') {
                    window.Telegram.WebApp.HapticFeedback.notificationOccurred('error');
                } else {
                    window.Telegram.WebApp.HapticFeedback.notificationOccurred('warning');
                }
                console.log(responsePayTG);
            } else {
                window.Telegram.WebApp.showAlert(t("start_payment_failed"));
            }
        }
    };

    const translatedText = t('you_have_test_period').split('\\n').map((line, index) => (
        <React.Fragment key={index}>
            {line}
            <br />
        </React.Fragment>
    ));

    return (
        <div className="col-12 p-1">
            {!isOpen && (
                <ListItem 
                    disableGutters 
                    sx={{ 
                        display: 'flex', 
                        alignItems: 'center',
                        padding: '8px',
                        backgroundColor: 'var(--tg-theme-bg-color)',
                        borderRadius: '12px', 
                        marginBottom: '2px',
                    }}
                    onClick={handleOpen}
                >
                    <Box sx={{ flex: '0 0 40px', marginRight: '16px' }}>
                        <img 
                            src={shop.image} 
                            width="100%" 
                            className="rounded" 
                            alt={shop.bot_tg_username} 
                            style={{ 
                                borderRadius: '12px', 
                            }} 
                        />
                    </Box>

                    <Box sx={{ flex: '1' }}>
                        <p style={{ fontSize: '16px', fontWeight: 'bold', margin: 0 }}>
                            {shop.bot_tg_username} {userData.oo !== undefined && userData.oo === true ? (`#${shop.shopID}`) : null}
                        </p>

                        {userData.oo !== undefined && userData.oo === true ? (
                            <Box>
                                {shop.end_pay_date !== null && (
                                    <>
                                        <p style={{ fontSize: '14px', color: 'var(--tg-theme-text-secondary-color)', margin: 0 }}>
                                            {t(calculateDaysDifference(shop.end_pay_date) <= 0 ? 'subscription_expired' : 'subscription_expires', { 
                                                date: format(new Date(shop.end_pay_date.slice(0, -9)), 'd MMMM yyyy', { locale: ru }) 
                                            })}
                                        </p>

                                        <p style={{ fontSize: '14px', margin: 0, color: calculateDaysDifference(shop.end_pay_date) <= 0 ? 'red' : 'green' }}>
                                            {t(calculateDaysDifference(shop.end_pay_date) <= 0 ? 'shop_overdue' : 'shop_paid_for', { 
                                                days: calculateDaysDifference(shop.end_pay_date)
                                            })}
                                        </p>
                                    </>
                                )}
                            </Box>
                        ) : null}
                    </Box>
            
                    <IconButton>
                        <ChevronRightIcon />
                    </IconButton>
                </ListItem>
            )}
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
                sx={{ background: "var(--tg-theme-secondary-bg-color)" }}
            >
                <DialogTitle sx={{background: "var(--tg-theme-secondary-bg-color)", display: "flex", alignItems: "center"}} id="responsive-dialog-title">
                    <img src={shop.image} alt={shop.bot_tg_username} className="rounded"
                         style={{width: '60px', marginTop: '10px', borderRadius: "12px"}}/>

                    <a style={{marginLeft: "5px"}} href={`https://t.me/@${shop.bot_tg_username}`}>@{shop.bot_tg_username}</a>
                </DialogTitle>
                <DialogContent sx={{background: "var(--tg-theme-secondary-bg-color)"}}>
                    <DialogContentText sx={{mt: 2}}></DialogContentText>
                    <Button className={"w-100 ios-style-button mainButton"} onClick={() => window.location.href = shop.webapp_link}>{t('open_catalog')}</Button>
                    {stats.total_users !== undefined && (
                        <div>
                            <p>{t('shop_exists_for_days', { days: stats.days_since_creation })}</p>
                            <p>{t('total_users', { count: stats.total_users.toLocaleString() })}</p>
                            <p>{t('new_users_today', { count: stats.users_today.toLocaleString() })}</p>
                            <p>{t('total_orders', { count: stats.total_orders.toLocaleString() })}</p>
                            <p>{t('monthly_orders', { count: stats.total_orders_month.toLocaleString() })}</p>
                            <p>{t('today_orders', { count: stats.total_orders_today.toLocaleString() })}</p>
                            <p>{t('total_cost', { amount: stats.total_cost.toLocaleString() })}</p>
                            <p>{t('monthly_cost', { amount: stats.total_month_cost.toLocaleString() })}</p>
                            <p>{t('today_cost', { amount: stats.total_day_cost.toLocaleString() })}</p>
                            <p>{t('total_items', { count: stats.total_items.toLocaleString() })}</p>
                            <p>{t('items_added_today', { count: stats.items_day.toLocaleString() })}</p>
                        </div>
                    )}
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default ShopCard;
